input[type='text']:not(#cw-spoiler-input),
input[type='search'],
input[type='number'],
textarea[id='upload-modal__description'],
input:not([type]) {
  background: $ui-base-color !important;
  color: $primary-text-color !important;

  &.setting-text {
    border-color: $darker-text-color;
  }
}

.column-content-select__control,
.button.button-secondary {
  border-color: $darker-text-color !important;
}

.column-content-select__control,
.column-content-select__menu {
  background: $ui-base-color !important;
}

.column-content-select__single-value {
  color: $classic-secondary-color !important;
}

.compose-form__warning,
.modal-root__modal {
  background: lighten($classic-base-color, 12%);
}

.boost-modal__action-bar,
.confirmation-modal__action-bar,
.mute-modal__action-bar,
.block-modal__action-bar {
  background: lighten($classic-base-color, 2%);
}
